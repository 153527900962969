export const TABS_DATA = {
  tsmbu: self !== top ? [
    {
      name: '全部',
      value: '0'
    },
    {
      name: '图片',
      value: '3',
      catId: 1
    },
    {
      name: '图文',
      value: '2',
      catId: 4
    },
    {
      name: '视频',
      value: '7',
      catId: 3
    },
    {
      name: '话术',
      value: '1',
      catId: 5
    }
  ] : [
    {
      name: '全部',
      value: '0'
    },
    {
      name: '图片',
      value: '3',
      catId: 1
    },
    {
      name: '海报',
      value: '9',
      catId: 2
    },
    {
      name: '图文',
      value: '2',
      catId: 4
    },
    {
      name: '视频',
      value: '7',
      catId: 3
    },
    {
      name: '文件',
      value: '8'
    },
    {
      name: '话术',
      value: '1',
      catId: 5
    }
  ],
  gm: self !== top ? [
    {
      name: '全部',
      value: '0'
    },
    {
      name: '图片',
      value: '3',
      catId: 1
    },
    {
      name: '图文',
      value: '2',
      catId: 4
    },
    {
      name: '视频',
      value: '7',
      catId: 3
    },
    {
      name: '话术',
      value: '1',
      catId: 5
    }
  ] : [
    {
      name: '全部',
      value: '0'
    },
    {
      name: '图片',
      value: '3',
      catId: 1
    },
    {
      name: '海报',
      value: '9',
      catId: 2
    },
    {
      name: '图文',
      value: '2',
      catId: 4
    },
    {
      name: '视频',
      value: '7',
      catId: 3
    },
    {
      name: '文件',
      value: '8'
    },
    {
      name: '话术',
      value: '1',
      catId: 5
    }
  ],
  audi: self !== top ? [
    {
      name: '推荐',
      value: '-1'
    },
    {
      name: '全部',
      value: '0'
    },
    {
      name: '图片',
      value: '3',
      catId: 1
    },
    {
      name: '多媒体',
      value: '5',
      catId: 3
    },
    {
      name: '文章',
      value: '2',
      catId: 4
    },
    {
      name: '话术',
      value: '1',
      catId: 5
    },
    // {
    //   name: '活动',
    //   value: '10'
    // }
    {
      name: '车型库',
      value: '12',
      catId: 12
    },
    {
      name: '经销商',
      value: '11',
      catId: 11
    }
  ] : [
    {
      name: '推荐',
      value: '-1'
    },
    {
      name: '全部',
      value: '0'
    },
    {
      name: '图片',
      value: '3',
      catId: 1
    },
    {
      name: '海报',
      value: '4',
      catId: 2
    },
    {
      name: '多媒体',
      value: '5',
      catId: 3
    },
    {
      name: '文章',
      value: '2',
      catId: 4
    },
    {
      name: '话术',
      value: '1',
      catId: 5
    },
    {
      name: '文件',
      value: '6'
    },
    // {
    //   name: '活动',
    //   value: '10'
    // }
    {
      name: '车型库',
      value: '12',
      catId: 12
    },
    {
      name: '经销商',
      value: '11',
      catId: 11
    }
  ]
}

export const RELATED_TABS_DATA = {
  tsmbu: [
    {
      name: '图片',
      value: '3'
    },
    {
      name: '海报',
      value: '9'
    },
    {
      name: '图文',
      value: '2'
    },
    {
      name: '视频',
      value: '7'
    },
    {
      name: '文件',
      value: '8'
    },
    {
      name: '话术',
      value: '1'
    }
  ],
  gm: [
    {
      name: '图片',
      value: '3'
    },
    {
      name: '海报',
      value: '9'
    },
    {
      name: '图文',
      value: '2'
    },
    {
      name: '视频',
      value: '7'
    },
    {
      name: '文件',
      value: '8'
    },
    {
      name: '话术',
      value: '1'
    }
  ],
  audi: [
    {
      name: '图片',
      value: '3'
    },
    {
      name: '海报',
      value: '4'
    },
    {
      name: '多媒体',
      value: '5'
    },
    {
      name: '文章',
      value: '2'
    },
    {
      name: '话术',
      value: '1'
    }
  ]
}
