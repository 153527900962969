<!--  -->
<template>
  <div>
    <div v-show="!hasBeenDeleted" class="posterDetail">
      <div class="imgBox">
        <img :src="detail.detailUrl" alt />
      </div>
      <div class="titleBox">
        <h4>{{ detail.title }}</h4>
        <p>
          <template
v-if="detail.width != null && detail.width != null"
            >{{ detail.width }}*{{ detail.height }} |</template
          >
          {{ detail.extension }}
        </p>
        <div v-if="!notInQw" style="cursor: pointer" @click="share(detail)">转发</div>
      </div>
      <div v-if="list && list.length && !notInQw" class="relation-detail">
        <div class="search-wrapper">
          <div class="title">相关推荐</div>
        </div>
        <card-list
          ref="cardList"
          :list="list"
          :search-params="searchParams"
          :top-search-map="topSearchMap"
          :page-data-total-count="pageDataTotalCount"
          :empty-error-type="emptyErrorType"
          @loadMorePage="loadMorePage"
        />
      </div>
    </div>
    <EmptyError v-show="hasBeenDeleted" />
  </div>
</template>

<script>
import wxSDK from '@/scripts/wxsdk'
import EmptyError from './EmptyError.vue'
import { articleMaterialDetail, linkList } from '@/services/materialServices'
import { getUrlParamsObj } from '@/scripts/utils'
import { sendData } from '@/scripts/iframe'
import { Toast } from 'vant'
import { v4 as uuidv4 } from 'uuid'
// import CardList from './CardList.vue'
import { TABS_DATA } from '@/modules/vSearch/constConfig'

export default {
  components: {
    // CardList,
    EmptyError },
  data() {
    return {
      detail: {},
      list: [],
      notInQw: true,
      hasBeenDeleted: false,
      pageDataTotalCount: 0,
      topSearchMap: {
        keyWord: '',
        type: '0'
      },
      searchParams: {
        // appId: 0,
        keyword: this.$route.query.keyWord || '',
        materialType: 1,
        publishTime: '',
        beginTime: '',
        endTime: '',
        categoryLv1Ids: [],
        categoryLv2Ids: [],
        pageIndex: 1,
        pageSize: 10
      },
      emptyErrorType: '',
      loading: false,
      proj: process.env.VUE_APP_PROJ
    }
  },
  watch: {
    $route(to, from) {
      // 刷新参数放到这里里面去触发就可以刷新相同界面了
      console.log(to, from, 'watch-$route')
      if (to.params.id || to.query.id) {
        this.init()
      }
    }
  },
  async mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.detail = {}
      this.list = []
      this.pageDataTotalCount = 0
      this.emptyErrorType = ''
      this.searchParams.pageIndex = 1
      this.searchParams.keyword = this.$route.query.keyWord || ''
      this.searchParams.pageSize = 10
      this.notInQw = getUrlParamsObj().notInQw === '1'
      this.materialDetail(this.$route.params.id || this.$route.query.id)
      // this.linkList(this.$route.params.id || this.$route.query.id)
      this.changetype()
    },
    detailViewTrack(item) {
      this._track({
        clickType: 5,
        query: JSON.stringify(this.searchParams),
        clickId: this.$route.params.id || this.$route.query.id,
        clickTitle: this.filterLabels(item.title),
        contentType: [Number(this.topSearchMap.type)],
        fields: this.$route.query.keyWord ? [this.$route.query.keyWord] : [],
        offset: this.$route.query.offset
      })
    },
    changetype() {
      var type = '0'
      if (this.$route.name === 'graphicDetail') {
        type = '3'
      }
      else {
        type = '4'
      }
      this.topSearchMap.type = type
    },
    async materialDetail() {
      const { id, catId } = this.$route.query
      const res = await articleMaterialDetail({ contentIds: [id], catId })
      if (res.code === 1 && res.data && res.data[0]) {
        this.detail = res.data[0]
        if (this.$route.query.pageFrom && this.$route.query.pageFrom === 'searchList') {
          this.detailViewTrack(this.detail)
        }
      }
      else if (res.code === 5011) {
        this.hasBeenDeleted = true
      }
    },
    loadMorePage() {
      this.searchParams.pageIndex += 1
      // this.linkList(this.$route.params.id || this.$route.query.id)
    },
    async linkList(id) {
      const res = await linkList({
        id,
        pageIndex: this.searchParams.pageIndex,
        pageSize: this.searchParams.pageSize
      })
      console.log(res)
      if (res.code === 0) {
        this.pageDataTotalCount = res.data.page ? res.data.page.totalCount : 0
        res.data.dataList.map(item => {
          if (this.topSearchMap.type === '3') {
            // 图片
            item.cardData = {
              id: item.id,
              title: item.title,
              desc: item.description,
              content: '',
              imageUrl: item.cosPath,
              orgName: item.orgName,
              time: item.updateTime
            }
          }
          else if (this.topSearchMap.type === '9' || this.topSearchMap.type === '4') {
            // 海报
            item.cardData = {
              id: item.id,
              title: item.title,
              desc: item.description,
              content: item.content,
              imageUrl: item.cosPath,
              orgName: item.orgName,
              time: item.updateTime
            }
          }
        })
        this.list = this.list.concat(res.data.dataList)
        if (this.list.length === 0) {
          this.emptyErrorType = 'empty'
        }
      }
      else {
        this.emptyErrorType = 'error'
      }
    },
    async share(item) {
      console.log('转发/发送item数据', item)
      let materialDetailUrl = ''
      if (this.proj !== 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/graphicDetail/${this.$route.params.id || this.$route.query.id}?id=${this.$route.params.id || this.$route.query.id}&notInQw=1`
      }
      if (this.proj === 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/kml-h5/vSearch/${this.detail.catId === 1 ? 'graphicDetail' : 'posterDetail'}?id=${this.$route.params.id || this.$route.query.id}&notInQw=1`
      }
      if (self !== top) {
        const catId = TABS_DATA[process.env.VUE_APP_PROJ].filter(item => item.value === this.topSearchMap.type)[0].catId
        const data = {
          method: 'send',
          id: uuidv4(),
          data: {
            ...item,
            catId,
            imgUrl: item.cosPath,
            materialDetailUrl,
            trackData: {
              clickType: 3, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
              scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
              url: location.href, // 当前页面地址
              contentSource: 'knowledge', // 暂时写死knowledge
              redirectFrom: '', // 页面访问来源
              link: '', // 传播链,记录用户id
              searchLimit: '10', // 当前搜索结果限制数量
              matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
              query: JSON.stringify(this.searchParams), // 搜索接口参数对象
              sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
              searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
              searchTimeEnd: '', // 用做搜索结束时间点
              clickId: this.$route.params.id || this.$route.query.id, // 转发素材id
              clickTitle: this.filterLabels(item.title), // 转发素材标题
              contentType: [Number(this.topSearchMap.type)], // 1话术，2图文，3图片，4视频，5文件，6海报
              userOrgId: this.notInQw ? '' : this.userInfo.org[0], // 转发者所属部门----需要主部门ID
              userId: this.notInQw ? '' : this.userInfo.id,
              userName: this.notInQw ? '' : this.userInfo.userName, // 当前用户名称
              tenantId: this.notInQw ? '' : this.userInfo.tenant, // 当前用户名称
              fields: this.$route.query.keyWord ? [this.$route.query.keyWord] : [], // 当前搜索过滤字段
              offset: this.$route.query.offset // 转发成功内容在总数据中第几条
            }
          }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        const contentJson = {
          title: item.title, // 分享标题
          desc: item.description ? item.description : ' ', // 分享描述
          link: materialDetailUrl, // 分享链接
          imgUrl: item.imageUrl || item.cosPath || '' // 分享封面
        }
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('news', contentJson)
          // await wxSDK.shareAppMessage(contentJson)
          console.log('企微中发送埋点数据')
          this._track({
            clickType: 3,
            query: JSON.stringify(this.searchParams),
            clickId: this.$route.params.id || this.$route.query.id,
            clickTitle: this.filterLabels(item.title),
            contentType: [Number(this.topSearchMap.type)],
            fields: this.$route.query.keyWord ? [this.$route.query.keyWord] : [],
            offset: this.$route.query.offset
          })
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.posterDetail {
  // height: 280px; background: #050404; width: 375px;
  .imgBox {
    max-width:1920px;
    margin:0px auto;
    > img {
      // height: 100%;
      display: block;
      margin: 0 auto;
      max-width: 100%;
      object-fit: cover;
    }
  }
  // .imgBox-audi {
  //   width: 375px;
  //   height: 280px;
  //   background: #050404;
  //   > img {
  //     height: 100%;
  //     display: block;
  //     margin: 0 auto;
  //     max-width: 100%;
  //     object-fit: cover;
  //   }
  // }
  .titleBox {
    padding: 15px;
    background: #fffeff;
    box-shadow: 0 0 4px 5px rgba(141, 141, 141, 0.05);
    h4 {
      font-family: 'DFPKingGothicGB-Regular';
      margin: 0;
      font-size: 16px;
      color: #050404;
      text-align: left;
      line-height: 24px;
    }
    p {
      margin: 10px 0;
      font-family: 'AudiTypeVar-Normal';
      font-size: 12px;
      color: #666666;
      text-align: left;
      line-height: 20px;
    }
    div {
      width: 90px;
      margin: 0 auto;
      height: 32px;
      background: #000000;
      font-family: 'DFPKingGothicGB-Regular';
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 32px;
    }
  }
  .relation-detail {
    .search-wrapper {
      padding: 20px 0px 0 0px;
      .title {
        font-family: DFPKingGothicGB-Medium;
        padding: 0 15px;
        font-size: 16px;
        color: #050404;
        text-align: left;
        line-height: 24px;
      }
    }

    .list-wrapper {
      padding: 15px 15px 52px 15px;
      .list-item {
        background: #fff;
        padding: 10px;
        margin-bottom: 15px;
        .speech-art {
          .name {
            font-family: DFPKingGothicGB-Medium;
            font-size: 16px;
            color: #050404;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 10px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .content {
            font-family: DFPKingGothicGB-Regular;
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            line-height: 21px;
            margin-bottom: 15px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
          .btn {
            display: flex;
            justify-content: flex-end;
            font-family: DFPKingGothicGB-Regular;
            font-size: 12px;
            color: #000000;
            letter-spacing: 0;
            line-height: 21px;
            div {
              margin-left: 30px;
            }
          }
        }
        .article {
          .name {
            font-family: DFPKingGothicGB-Medium;
            font-size: 16px;
            color: #050404;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 10px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              width: 195px;
              .top {
                font-family: DFPKingGothicGB-Regular;
                font-size: 14px;
                color: #666666;
                letter-spacing: 0;
                line-height: 21px;
                margin-bottom: 11px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
              .bottom {
                font-family: DFPKingGothicGB-Regular;
                font-size: 10px;
                color: #999999;
                line-height: 16px;
              }
            }
            .right {
              width: 120px;
              height: 90px;
              background-size: 100%;
              // img {
              //   width: 120px;
              //   height: 90px;
              // }
              display: flex;
              align-items: center;
              overflow: hidden;
              img {
                width: 100%;
              }
            }
          }
        }
        // .name {
        //   font-family: DFPKingGothicGB-Medium;
        //   font-size: 16px;
        //   color: #050404;
        //   letter-spacing: 0;
        //   line-height: 24px;
        //   margin-bottom: 10px;
        // }
        // .content {
        //   font-family: DFPKingGothicGB-Regular;
        //   font-size: 14px;
        //   color: #666666;
        //   letter-spacing: 0;
        //   line-height: 21px;
        //   margin-bottom: 15px;
        // }
        // .btn {
        //   display: flex;
        //   justify-content: flex-end;
        //   font-family: DFPKingGothicGB-Regular;
        //   font-size: 12px;
        //   color: #000000;
        //   letter-spacing: 0;
        //   line-height: 21px;
        //   div {
        //     margin-left: 30px;
        //   }
        // }
      }
    }
  }
}
</style>
