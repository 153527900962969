<template>
  <div>
    <div v-show="!hasBeenDeleted" class="article-detail">
      <div v-show="detail.contentId">
        <div class="title">{{ detail.title || '/' }}</div>
        <div class="btns">
          <div class="left">
            <div class="time">{{ detail.updateTime ? timeTypeFomate(detail.updateTime) : '--' }}</div>
          </div>
          <van-button v-if="!notInQw" color="#000" @click="share(detail)">转发</van-button>
        </div>
        <div class="content">
          <div  v-html="detail.content || '/'"></div>
        </div>
      </div>
    </div>
    <EmptyError v-show="hasBeenDeleted" />
  </div>
</template>
<script>
import { articleMaterialDetail } from '@/services/materialServices'
import EmptyError from '../components/EmptyError.vue'
import { sendData } from '@/scripts/iframe'
import wxSDK from '@/scripts/wxsdk'
import { getUrlParamsObj } from '@/scripts/utils'
import moment from 'moment'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ArticleDetail',
  components: { EmptyError },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ,
      detail: {},
      hasBeenDeleted: false,
      notInQw: true,
      moment
    }
  },
  computed: {
    ...mapGetters({
      authorizeInfo: 'userAuth/getAuthorizeInfo',
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  watch: {
    $route(to, from) {
      // 刷新参数放到这里里面去触发就可以刷新相同界面了
      console.log(to, from, 'watch-$route')
      if (to.params.id || to.query.id) {
        this.init()
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.moment = moment
      this.notInQw = getUrlParamsObj().notInQw
      this.articleMaterialDetail(this.$route.params.id || this.$route.query.id)
    },
    detailViewTrack(item) {
      this._track({
        clickType: 5,
        query: '',
        clickId: this.$route.params.id || this.$route.query.id,
        clickTitle: this.filterLabels(item.title),
        contentType: [2],
        fields: this.$route.query.keyWord ? [this.$route.query.keyWord] : [],
        offset: this.$route.query.offset
      })
    },
    timeTypeFomate(date) {
      if (new Date(date).getDate() === new Date().getDate()) {
        return '今日发布'
      }
      else if (
        new Date().getTime() - new Date(date).getTime() <=
        7 * 24 * 60 * 60 * 1000
      ) {
        const dayNum = Math.ceil(
          (new Date().getTime() - new Date(date).getTime()) /
            (24 * 60 * 60 * 1000)
        )
        console.log(dayNum)
        return `${dayNum}天前`
      }
      else {
        return moment(new Date(Number(date))).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    async articleMaterialDetail() {
      const { id, catId } = this.$route.query
      const res = await articleMaterialDetail({ contentIds: [id], catId })
      if (res.code == 1) {
        this.detail = res.data[0]
        if (this.$route.query.pageFrom && this.$route.query.pageFrom === 'searchList') {
          this.detailViewTrack(this.detail)
        }
      }
      else if (res.code === 5011) {
        this.hasBeenDeleted = true
      }
    },
    async share(item) {
      console.log('转发/发送item数据', item)
      let materialDetailUrl = ''
      if (this.proj !== 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/articleDetail/${this.$route.query.id || this.$route.params.id}?id=${this.$route.query.id || this.$route.params.id}&notInQw=1`
      }
      if (this.proj === 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/km-h5/vSearch/articleDetail?id=${this.$route.query.id}&notInQw=1`
      }
      if (self !== top) {
        const data = {
          method: 'send',
          id: uuidv4(),
          data: {
            ...item,
            catId: 4,
            materialDetailUrl,
            trackData: {
              clickType: 3, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
              scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
              url: location.href, // 当前页面地址
              contentSource: 'knowledge', // 暂时写死knowledge
              redirectFrom: '', // 页面访问来源
              link: '', // 传播链,记录用户id
              searchLimit: '10', // 当前搜索结果限制数量
              matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
              query: '', // 搜索接口参数对象
              sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
              searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
              searchTimeEnd: '', // 用做搜索结束时间点
              clickId: this.$route.params.id || this.$route.query.id, // 转发素材id
              clickTitle: this.filterLabels(item.title), // 转发素材标题
              contentType: [2], // 1话术，2图文，3图片，4视频，5文件，6海报
              userOrgId: this.notInQw ? '' : this.userInfo.org[0], // 转发者所属部门----需要主部门ID
              userId: this.notInQw ? '' : this.userInfo.id,
              userName: this.notInQw ? '' : this.userInfo.userName, // 当前用户名称
              tenantId: this.notInQw ? '' : this.userInfo.tenant, // 当前用户名称
              fields: this.$route.query.keyWord ? [this.$route.query.keyWord] : [], // 当前搜索过滤字段
              offset: this.$route.query.offset // 转发成功内容在总数据中第几条
            }
          }
        }
        if (this._isNetWorkOnLine()) {
          sendData(data)
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
      else {
        const contentJson = {
          title: item.title, // 分享标题
          desc: item.description ? item.description : ' ', // 分享描述
          link: materialDetailUrl, // 分享链接
          imgUrl: item.imgUrl || item.simpleImgUrl || '' // 分享封面
        }
        if (this._isNetWorkOnLine()) {
          await wxSDK.sendChatMessage('news', contentJson)
          // await wxSDK.shareAppMessage(contentJson)
          console.log('企微中发送埋点数据')
          this._track({
            clickType: 3,
            query: '',
            clickId: this.$route.params.id || this.$route.query.id,
            clickTitle: this.filterLabels(item.title),
            contentType: [2],
            fields: this.$route.query.keyWord ? [this.$route.query.keyWord] : [],
            offset: this.$route.query.offset
          })
        }
        else {
          Toast.fail({
            message: '网络异常，当前不可发送',
            duration: 3 * 1000
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scope>
.article-detail {
  padding: 0 18px;
  background: #fff;
  // height: 100vh;
  .title {
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #333333;
    letter-spacing: -0.3px;
    line-height: 32px;
    padding: 20px 0;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 29px;
    .left {
      .classify {
        font-family: DFPKingGothicGB-Medium;
        font-size: 14px;
        color: #666666;
        text-align: left;
        line-height: 20px;
        margin-bottom: 5px;
      }
      .time {
        font-family: AudiTypeVar-Normal;
        font-size: 12px;
        color: #999999;
        text-align: left;
        line-height: 20px;
      }
    }
    .van-button {
      width: 68px;
      height: 24px;
      border: 1px solid #000000;
      font-family: DFPKingGothicGB-Regular;
      font-size: 12px;
    }
  }
  .content {
    font-family: DFPKingGothicGB-Regular;
    font-size: 14px;
    color: #333333;
    text-align: left;
    line-height: 26px;
    overflow: scroll;
    img,
    video,
    svg {
      max-width: 100%;
    }
  }
}
</style>
