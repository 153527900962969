<template>
  <div class="top-search">
    <div
      v-if="hasInput"
      :style="searchPadding"
      class="search"
      :class="'theme-' + theme"
    >
      <van-search
        v-model="curTopSearchMap.keyWord"
        placeholder="请输入搜索关键词"
        show-action
        :clearable="true"
        :maxlength="maxlength"
        @search="search"
        @focus="showAssociativeData = true"
        @input="debounceFn"
      >
        <template #action>
          <div @click="search">搜索</div>
        </template>
      </van-search>
      <div
        v-show="
          showAssociativeData &&
          curTopSearchMap.keyWord !== '' &&
          curTopSearchMap.keyWord != null &&
          (associativeData.length || hotQuestion.length)
        "
        class="dropdown-wrapper"
      >
        <div
          v-for="(item, index) in associativeData"
          :key="index"
        >
          <div
            v-if="index < 5"
            class="item-wrapper item"
          >
            <img :src="searchIcon" alt="" class="item-search-icon" @click="associativeClick(item, 2)" />
            <div class="text" @click="associativeClick(item, 2)" v-html="renderText(item.word)" />
            <img v-if="index < 3 && item.isHot === 1" src="@/modules/vSearch/img/hot_icon.png" alt="" class="item-search-hot" />
            <img :src="searchArrow" alt="" class="item-search-arrow" @click="curTopSearchMap.keyWord = item.word; showAssociativeData = true; keyWordChange()" />
          </div>
          <div
            v-if="(index === 5 || index > 5 && index < 10) && (showMore || !platform)"
            class="item-wrapper item"
          >
            <img :src="searchIcon" alt="" class="item-search-icon" />
            <div class="text" @click="associativeClick(item, 2)" v-html="renderText(item.word)" />
            <img :src="searchArrow" alt="" class="item-search-arrow" @click="curTopSearchMap.keyWord = item.word; showAssociativeData = true; keyWordChange()" />
          </div>
        </div>
        <div v-if="associativeData.length > 5 && platform" class="item-more" @click="clickMore" @mouseover="hoverIcon = true" @mouseout="hoverIcon = false">
          <div>{{ showMore ? '收起更多' : '查看更多' }}</div>
          <img :src="hoverIcon ? btnMoreChecked : btnMoreNormal" :class="showMore ? 'icon-close' : 'icon'" alt="">
        </div>
        <div v-if="hotQuestion.length" class="item-hot">
          <div>搜索“<span class="hot">{{ curTopSearchMap.keyWord }}</span>”热问</div>
        </div>
        <div v-if="hotQuestion.length">
          <div
            v-for="(item, index) in hotQuestion"
            :key="index"
          >
            <div
              v-if="index < 5"
              class="item-wrapper item"
              @click="associativeClick(item, 3)"
            >
              <img :src="searchIcon" alt="" class="item-search-icon" />
              <div class="text" v-html="renderHotText(item.word)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :style="searchPadding" class="tab_nav">
      <div
        :style="filterImg || filterImg === 'atrDetail' ? 'width:90%' : 'width:100%'"
        class="tab"
        :class="'theme-' + theme"
      >
        <van-tabs
          v-model="curTopSearchMap.type"
          :color="theme === 'night' ? '#fff' : '#000'"
          background="transparent"
          :title-active-color="theme === 'night' ? '#fff' : '#000'"
          title-inactive-color="#999"
          swipeable
          @change="typeChange"
        >
          <van-tab
            v-for="(item, index) in tabsData"
            :key="index"
            :title="item.name"
            :name="item.value"
          >
            <div v-if="$route.name !== 'vSearchHome' || proj === 'audi'" @click="closeSearch">
              <div v-if="item.name === '推荐'">
                <van-tabs
                  v-model="active"
                  class="myvantab"
                  @change="weekTotalChange">
                  <van-tab title="周排行">
                    <div :class="{'myvantabcondiv':true, 'myvantabblack': background === 'black'}">
                      <h1>图片类TOP10</h1>
                      <div>
                        <CardList
                           ref="cardListWeekImage"
                           :list="topRecommendSearchMapWeek.image.list"
                           :page-data-total-count="pageDataTotalCount"
                           :top-search-map="topSearchMap"
                           :top-recommend-search-map="topRecommendSearchMapWeek.image"
                           :search-params="searchParams"
                           :empty-error-type="emptyErrorType"
                           :loading="loading"
                           :has-fixed-btn="!isInIframe"
                           :double-col-shows="false"
                           :card-type="'推荐'"
                           @subCheckValList="subChooseWeekImage"
                           @loadMorePage="loadMorePage"
                           @typeChange="typeChange"
                           @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>多媒体类TOP5</h1>
                      <div>
                        <CardList
                          ref="cardListWeekMedia"
                          :list="topRecommendSearchMapWeek.video.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapWeek.video"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @subCheckValList="subChooseWeekMedia"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>文章类TOP5</h1>
                      <div>
                        <CardList
                          ref="cardListArticle"
                          :list="topRecommendSearchMapWeek.article.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapWeek.article"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @subCheckValList="subChooseWeekArticle"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>话术类TOP10</h1>
                      <div>
                        <CardList
                          ref="cardList222"
                          :list="topRecommendSearchMapWeek.char.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapWeek.char"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                    </div>
                  </van-tab>
                  <van-tab title="累计排行">
                    <div :class="{'myvantabcondiv':true, 'myvantabblack': background === 'black'}">
                      <h1>图片类TOP10</h1>
                      <div>
                        <CardList
                          ref="cardListWeekImage2"
                          :list="topRecommendSearchMapTotal.image.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapTotal.image"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @subCheckValList="subChooseWeekImageTotal"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>多媒体类TOP5</h1>
                      <div>
                        <CardList
                           ref="cardListWeekMedia2"
                           :list="topRecommendSearchMapTotal.video.list"
                           :page-data-total-count="pageDataTotalCount"
                           :top-search-map="topSearchMap"
                           :top-recommend-search-map="topRecommendSearchMapTotal.video"
                           :search-params="searchParams"
                           :empty-error-type="emptyErrorType"
                           :loading="loading"
                           :has-fixed-btn="!isInIframe"
                           :double-col-shows="false"
                           :card-type="'推荐'"
                           @subCheckValList="subChooseWeekMediaTotal"
                           @loadMorePage="loadMorePage"
                           @typeChange="typeChange"
                           @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>文章类TOP5</h1>
                      <div>
                        <CardList
                          ref="cardListArticle2"
                          :list="topRecommendSearchMapTotal.article.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapTotal.article"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @subCheckValList="subChooseWeekArticleTotal"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                      <h1>话术类TOP10</h1>
                      <div>
                        <CardList
                          ref="cardList44"
                          :list="topRecommendSearchMapTotal.char.list"
                          :page-data-total-count="pageDataTotalCount"
                          :top-search-map="topSearchMap"
                          :top-recommend-search-map="topRecommendSearchMapTotal.char"
                          :search-params="searchParams"
                          :empty-error-type="emptyErrorType"
                          :loading="loading"
                          :has-fixed-btn="!isInIframe"
                          :double-col-shows="false"
                          :card-type="'推荐'"
                          @loadMorePage="loadMorePage"
                          @typeChange="typeChange"
                          @updateKeyword="updateKeyword"
                        />
                      </div>
                    </div>
                  </van-tab>
                </van-tabs>
              </div>
              <CardList
                v-else-if="$route.name !== 'vSearchHome' || (topSearchMap.type === '-1' && $route.name === 'vSearchHome')"
                ref="cardList"
                :list="list"
                :page-data-total-count="pageDataTotalCount"
                :top-search-map="topSearchMap"
                :search-params="searchParams"
                :empty-error-type="emptyErrorType"
                :loading="loading"
                :has-fixed-btn="!isInIframe && hasFixedBtn"
                @loadMorePage="loadMorePage"
                @typeChange="typeChange"
                @updateKeyword="updateKeyword"
              />
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div
        v-show="filterImg && filterImg !== 'atrDetail' && topSearchMap.type !== '12' && topSearchMap.type !== '11'"
        class="select_icon"
      >
        <img :src="filterImg" alt="" @click="filterSelect" />
        <div v-if="filterAllShow" class="filter-box">
          <div class="filter-box-item" @click="sure('')">按相关度</div>
          <div class="filter-box-item" @click="sure('materialUpdateTime')">按最新时间</div>
        </div>
      </div>
    </div>
    <div
      v-show="proj === 'audi' && topSearchMap.type === '-1'"
      class="fixed-btn"
      @click="cardListCheck"
    >
      <img :src="isCheckOpen ? checkOpen : checkClose" alt="" />
      <span>多选</span>
    </div>
    <div v-show="isCheckOpen" class="checked-send-btn">
      <van-button color="#020002" @click="multiShare">转发</van-button>
    </div>
  </div>
</template>

<script>
import { searchSuggest } from '@/services/materialServices'

import { debounce } from 'lodash'
import hotIcon from '@/modules/vSearch/img/global_icon_hot@2x.png'
import btnMoreNormal from '@/modules/vSearch/img/btn_more_normal@2x.png'
import btnMoreChecked from '@/modules/vSearch/img/btn_more_checked@2x.png'
import clearIcon from '@/modules/vSearch/img/clear-icon@2x.png'
import searchIcon from '@/modules/vSearch/img/search_icon@2x.png'
import searchArrow from '@/modules/vSearch/img/search_arrow@2x.png'
// import CardList from '@/components/CardList.vue'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
import checkClose from '@/modules/vSearch/img/check-close@2x.png'
import checkOpen from '@/modules/vSearch/img/check-open@2x.png'
import shareDefault from '@/modules/vSearch/img/share-default.png'
import wxSDK from '@/scripts/wxsdk'
export default {
  name: 'TopSearch',
  // components: { CardList },
  props: {
    background: {
      type: String,
      requried: false,
      default: () => {
        return ''
      }
    },
    theme: {
      type: String,
      default: 'night'
    },
    stickyClass: {
      type: String,
      default: 'sticky'
    },
    searchPadding: {
      type: Object,
      default: () => {}
    },
    filterImg: {
      type: String,
      default: null
    },
    hasInput: {
      type: Boolean,
      default: true
    },
    showAssociativeData: {
      type: Boolean,
      default: false
    },
    hasFixedBtn: {
      type: Boolean,
      default: true
    },
    tabsData: {
      type: Array,
      default: () => []
    },
    topSearchMap: {
      type: Object,
      default: () => {}
    },
    topRecommendSearchMapWeek: {
      type: Object,
      required: false,
      default: () => {
        return {
          'image': {
            keyWord: '',
            type: '3',
            list: []
          },
          'video': {
            keyWord: '',
            type: '5',
            list: []
          },
          'article': {
            keyWord: '',
            type: '2',
            list: []
          },
          'char': {
            keyWord: '',
            type: '1',
            list: []
          }
        }
      }
    },
    topRecommendSearchMapTotal: {
      type: Object,
      required: false,
      default: () => {
        return {
          'image': {
            keyWord: '',
            type: '3',
            list: []
          },
          'video': {
            keyWord: '',
            type: '5',
            list: []
          },
          'article': {
            keyWord: '',
            type: '2',
            list: []
          },
          'char': {
            keyWord: '',
            type: '1',
            list: []
          }
        }
      }
    },
    maxlength: {
      type: String,
      default: '1000'
    },

    list: {
      type: Array,
      default: () => []
    },
    videoList: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    pageDataTotalCount: {
      type: Number,
      default: 0
    },
    searchParams: {
      type: Object,
      default: () => {}
    },
    emptyErrorType: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    isInIframe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ,
      isCheckOpen: false,
      checkClose,
      checkOpen,
      // 推荐里面的默认选项
      active: 0,
      filterAllShow: false,
      searchFocus: false,
      showMore: false,
      curTopSearchMap: {
        keyWord: '',
        type: '',
        querySource: ''
      },
      hoverIcon: false,
      hotIcon,
      btnMoreNormal,
      btnMoreChecked,
      clearIcon,
      searchIcon,
      searchArrow,
      platform: true,
      associativeData: [],
      hotQuestion: [],
      debounceFn: () => {},
      // 需要转发的 周排行图片数据
      cardListWeekImage: [],
      // 需要转发的 总排行图片数据
      cardListWeekImage2: [],
      // 需要转发的 周排行视频数据
      cardListWeekMedia: [],
      // 需要转发的 总排行视频数据
      cardListWeekMedia2: [],
      cardListArticle: [],
      cardListArticle2: []
    }
  },
  computed: {
    ...mapGetters({
      authorizeInfo: 'userAuth/getAuthorizeInfo',
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  watch: {
    topSearchMap: {
      handler(val) {
        this.curTopSearchMap = { ...this.topSearchMap }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.platform = this._userAgentType().platform === 'pc'
    this.curTopSearchMap = { ...this.topSearchMap }
    // 联想搜索防抖
    this.debounceFn = debounce(this.keyWordChange, 50)
  },
  methods: {
    cardListCheck() {
      if (this.topSearchMap.type === '0') {
        this.isCheckOpen = !this.isCheckOpen
      }
      else {
        this.isCheckOpen = !this.isCheckOpen
        if (this.$refs.cardListWeekImage) {
          this.$refs.cardListWeekImage[0].cardListCheck(true)
        }
        if (this.$refs.cardListWeekMedia) {
          this.$refs.cardListWeekMedia[0].cardListCheck(true)
        }
        if (this.$refs.cardListArticle) {
          this.$refs.cardListArticle[0].cardListCheck(true)
        }
        if (this.$refs.cardListWeekImage2) {
          this.$refs.cardListWeekImage2[0].cardListCheck(true)
        }
        if (this.$refs.cardListWeekMedia2) {
          this.$refs.cardListWeekMedia2[0].cardListCheck(true)
        }
        if (this.$refs.cardListArticle2) {
          this.$refs.cardListArticle2[0].cardListCheck(true)
        }
      }
    },
    weekTotalChange() {
      if (this.isCheckOpen === true) {
        this.$nextTick(() => {
          this.$refs.cardListWeekImage2[0].cardListCheck(true)
          this.$refs.cardListWeekMedia2[0].cardListCheck(true)
          this.$refs.cardListArticle2[0].cardListCheck(true)
        })
      }
    },
    checkAllMaterials() {
      if (this.cardListWeekImage.length !== 0) {
        return true
      }
      if (this.cardListWeekImage2.length !== 0) {
        return true
      }
      if (this.cardListWeekMedia.length !== 0) {
        return true
      }
      if (this.cardListWeekMedia2.length !== 0) {
        return true
      }
      if (this.cardListArticle.length !== 0) {
        return true
      }
      if (this.cardListArticle2.length !== 0) {
        return true
      }
      return false
    },
    // 图片周排行
    subChooseWeekImage(arr) {
      this.cardListWeekImage = arr
    },
    // 视频周排行
    subChooseWeekMedia(arr) {
      this.cardListWeekMedia = arr
    },
    // 文章周排行
    subChooseWeekArticle(arr) {
      this.cardListArticle = arr
    },
    // 图片总排行
    subChooseWeekImageTotal(arr) {
      this.cardListWeekImage2 = arr
    },
    // 视频总排行
    subChooseWeekMediaTotal(arr) {
      this.cardListWeekMedia2 = arr
    },
    // 文章总排行
    subChooseWeekArticleTotal(arr) {
      this.cardListArticle2 = arr
    },
    clearShareList() {
      this.cardListWeekImage = []
      this.cardListWeekImage2 = []
      this.cardListWeekMedia = []
      this.cardListWeekMedia2 = []
      this.cardListArticle = []
      this.cardListArticle2 = []
      this.isCheckOpen = false
      if (this.$refs.cardListWeekImage) {
        this.$refs.cardListWeekImage[0].clearAll2()
      }
      if (this.$refs.cardListWeekMedia) {
        this.$refs.cardListWeekMedia[0].clearAll2()
      }
      if (this.$refs.cardListArticle) {
        this.$refs.cardListArticle[0].clearAll2()
      }
      if (this.$refs.cardListArticle2) {
        this.$refs.cardListArticle2[0].clearAll2()
      }
      if (this.$refs.cardListWeekImage2) {
        this.$refs.cardListWeekImage2[0].clearAll2()
      }
      if (this.$refs.cardListWeekMedia2) {
        this.$refs.cardListWeekMedia2[0].clearAll2()
      }
    },
    async multiShare() {
      if (this.checkAllMaterials() === false) {
        Toast.fail({
          message: '请选择素材',
          duration: 2 * 1000
        })
        return
      }
      if (this.cardListWeekImage.length > 0) {
        await this.multiShareSend(this.cardListWeekImage, this.topRecommendSearchMapWeek.image.list, 3)
      }
      if (this.cardListWeekImage2.length > 0) {
        await this.multiShareSend(this.cardListWeekImage2, this.topRecommendSearchMapTotal.image.list, 3)
      }
      if (this.cardListWeekMedia.length > 0) {
        await this.multiShareSend(this.cardListWeekMedia, this.topRecommendSearchMapWeek.video.list, 5)
      }
      if (this.cardListWeekMedia2.length > 0) {
        await this.multiShareSend(this.cardListWeekMedia2, this.topRecommendSearchMapTotal.video.list, 5)
      }
      if (this.cardListArticle.length > 0) {
        await this.multiShareSend(this.cardListArticle, this.topRecommendSearchMapWeek.article.list, 2)
      }
      if (this.cardListArticle2.length > 0) {
        await this.multiShareSend(this.cardListArticle2, this.topRecommendSearchMapTotal.article.list, 2)
      }
      this.clearShareList()
    },
    getCardListCheck(arr1, arr2) {
      const checkedList = []
      arr1.map(item => {
        arr2.map(item2 => {
          if (item === item2.cardData.id) {
            checkedList.push(item2)
          }
        })
      })
      return checkedList
    },
    getCardListCheckOffsetArr(arr1, arr2) {
      const offsetArr = []
      arr1.map(item => {
        arr2.map((item2, index2) => {
          if (item === (item2.cardData ? item2.cardData.id : item2.id)) {
            offsetArr.push(index2 + 1)
          }
        })
      })
      return offsetArr
    },
    async multiShareSend(arr1, arr2, material) {
      const checkedList = this.getCardListCheck(arr1, arr2)
      const offsetArr = this.getCardListCheckOffsetArr(arr1, arr2)
      const materialIdListJson = JSON.stringify(arr1)
      const offsetArrJson = JSON.stringify(offsetArr)
      const userInfoJson = JSON.stringify({
        userName: this.userInfo.userName,
        avatar: this.userInfo.avatar,
        shareTime: new Date().getTime()
      })
      const materialType = material
      let materialDetailUrl = ''
      if (this.proj === 'audi') {
        materialDetailUrl = `${location.protocol}//${location.host}/kml-h5/vSearch/multiSelectList?materialIdListJson=${materialIdListJson}&userInfoJson=${userInfoJson}&materialType=${materialType}&offsetArrJson=${offsetArrJson}&notInQw=1`
      }
      const contentJson = {
        title: '' + `${self !== top ? '客服' : this.userInfo.userName}转发的内容`, // 分享标题
        desc: '' + `${checkedList
          .map(
            (item, index) =>
              index + 1 + '.' + this.filterLabels(item.cardData.title)
          )
          .join(' ')}`, // 分享描述
        link: materialDetailUrl, // 分享链接
        imgUrl: checkedList[0].imgUrl || checkedList[0].cosPath || shareDefault // 分享封面
      }
      // eslint-disable-next-line no-constant-condition
      if (this._isNetWorkOnLine()) {
        await wxSDK.sendChatMessage('news', contentJson)
        // await wxSDK.shareAppMessage(contentJson)
        this.isCheckOpen = false
        this.feedBackCheckOpen = false
        // 企微内多选发送埋点处理: 发送成功后每条素材单独调用接口进行上报
        for (let index = 0; index < checkedList.length; index++) {
          this._track({
            clickType: 2,
            query: JSON.stringify(this.searchParams),
            clickId: checkedList[index].cardData.id,
            clickTitle: this.filterLabels(checkedList[index].cardData.title),
            contentType: [Number(material)],
            fields: [],
            offset: offsetArr[index]
          })
        }
      }
      else {
        Toast.fail({
          message: '网络异常，当前不可发送',
          duration: 3 * 1000
        })
      }
    },
    closeSearch() {
      this.$emit('closeSearch')
    },
    loadMorePage() {
      this.$emit('loadMorePage')
    },
    updateKeyword(keyWord) {
      this.$emit('updateKeyword', keyWord)
    },
    renderText(word) {
      const result = word ? word.replace(RegExp(this.curTopSearchMap.keyWord, 'g'), `<span style="color: #888888">${this.curTopSearchMap.keyWord}</span>`) : ''
      return result
    },
    renderHotText(word) {
      const result = word ? word.replace(RegExp(this.curTopSearchMap.keyWord, 'g'), `<span style="color: #F50537">${this.curTopSearchMap.keyWord}</span>`) : ''
      return result
    },
    clickMore() {
      this.showMore = !this.showMore
    },
    keyWordChange() {
      if (
        this.curTopSearchMap.keyWord !== '' &&
        this.curTopSearchMap.keyWord != null
      ) {
        this.searchSuggest()
      }
    },
    cardCheck() {
      this.$refs.cardList[0].cardListCheck()
    },
    async searchSuggest() {
      const res = await searchSuggest({ prefix: this.curTopSearchMap.keyWord })
      if (res.code === 0) {
        this.associativeData = res.data.filter(item => item.type === 0)
        this.hotQuestion = res.data.filter(item => item.type === 2)
      }
    },
    search(val, source) {
      const querySource = source || 1
      this.curTopSearchMap.querySource = querySource
      this.showAssociativeData = false
      // 当客户点击推荐栏目的搜索的时候，自动跳转到全部栏目，奥迪专用
      if (this.curTopSearchMap.type === '-1') {
        this.curTopSearchMap.type = '0'
      }
      this.$emit('search', { ...this.curTopSearchMap })
    },
    typeChange({ type }) {
      this.filterAllShow = false
      if (type) {
        this.$emit('typeChange', { ...this.curTopSearchMap, type })
      }
      else {
        this.$emit('typeChange', { ...this.curTopSearchMap })
      }
    },
    filterSelect() {
      if (this.curTopSearchMap.type === '0') {
        this.filterAllShow = !this.filterAllShow
      }
      else {
        this.$emit('filterSelect')
      }
    },
    associativeClick(item, source) {
      this.curTopSearchMap.keyWord = item.word
      if (item.type === 2) {
        this.curTopSearchMap.type = '1'
      }
      this.search(item.word, source)
    },
    sure(order) {
      this.filterAllShow = false
      this.$emit('sure', order)
    }
  }
}
</script>

<style scoped lang="less">
  .fixed-btn {
    width: 50px;
    cursor: pointer;
    height: 50px;
    background: #020002;
    border-radius: 50%;
    position: fixed;
    bottom: 120px;
    right: 0;
    font-size: 12px;
    font-family: PingFangSC-Regular;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    span {
      transform: scale(0.8);
    }
    img {
      width: 20px;
      height: 20px;
      margin-bottom: 3px;
    }
  }
  .checked-send-btn {
    cursor: pointer;
    width: 100%;
    height: 50px;
    background: #fff;
    z-index: 10;
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 99999;
    .van-button {
      display: block;
      position: absolute;
      right: 11px;
      width: 90px;
      height: 32px;
      font-family: DFPKingGothicGB-Regular;
      font-size: 16px;
      border-radius: 0;
    }
  }
.top-search {
  /deep/.search {
    margin-bottom: 7px;
    position: relative;
    .van-search {
      background: transparent;
      padding: 0;
      border-bottom: 2px solid #fff;
      .van-search__content {
        background: transparent;
        border-radius: 0;
        padding-left: 0;
        .van-cell,
        .van-field__control {
          color: #999;
          font-size: 16px;
          line-height: 40px;
          padding: 0;
        }
        .van-cell {
          .van-field__left-icon {
            .van-icon {
              font-size: 22px;
            }
          }
        }
      }
      .van-search__action {
        color: #fff;
      }
    }
  }
  .tab_nav {
    position: relative !important;
    padding: 0 !important;
    .select_icon {
      position: absolute;
      top: 0;
      right: 10px;
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
      .filter-box {
        position: absolute;
        z-index: 100;
        right: 0;
        top: 50px;
        width: 110px;
        background: #FFFFFF;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15);
        text-align: center;
        .filter-box-item {
          color: #999;
          font-size: 16px;
          line-height: 40px;
          padding: 4px 0;
        }
        .filter-box-item:hover {
          background: rgba(57,117,198,0.10);
        }
      }
    }
  }
  /deep/.tab {
    width: 100% !important;
    .van-tabs {
      .van-tabs__wrap {
        padding: 0 40px 0 0;
        .van-tabs__nav {
          overflow-x: auto !important;
          overflow-y: hidden !important;
          .van-tab {
            line-height: 35px;
            font-size: 16px;
            overflow: hidden !important;
          }
          .van-tabs__line {
            height: 2px;
          }
        }
        .van-tabs__nav::-webkit-scrollbar-thumb {
          background: gray !important;
          border-radius: unset !important;
        }
        .van-tabs__nav::-webkit-scrollbar {
          width: 8px !important;
          height: 20px !important;
          background: transparent !important;
          display: unset !important;
        }
      }
    }
  }
  /deep/.van-tab__pane, .van-tab__pane-wrapper {
    width: 100%;
  }
  /deep/.search.theme-night {
    .van-search {
      border-bottom: 2px solid #fff;
      .van-search__content {
        .van-cell,
        .van-field__control {
          color: #999;
        }
      }
      .van-search__action {
        color: #fff;
      }
      .van-search__action:active {
        background: #000;
      }
    }
  }
  /deep/.search.theme-day {
    .van-search {
      border-bottom: 2px solid #000;
      .van-search__content {
        .van-cell,
        .van-field__control {
          color: #000;
        }
      }
      .van-search__action {
        color: #000;
      }
    }
  }
  .dropdown-wrapper {
    position: absolute;
    width: 339px;
    background: #fff;
    // transition-duration: 0.2s;
    overflow: hidden;
    z-index: 100;
    padding: 20px 0;
    box-sizing: border-box;
    box-shadow: 0 0 4px 5px rgba(141, 141, 141, 0.05);
    max-height: 300px;
    overflow-y: scroll;
    .item-more {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      color: #888888;
      margin-bottom: 10px;
      .icon {
        width: 12px;
        height: 12px;
      }
      .icon-close {
        width: 12px;
        height: 12px;
        transform: rotate(180deg);
      }
    }
    .item-more:hover {
      color: #0078F0;
    }
    .item-hot {
      display: flex;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      box-sizing: border-box;
      padding: 0 10px;
      margin-top: 24px;
      margin-bottom: 12px;
      .item-hot-icon {
        width: 20px;
        height: 20px;
      }
      .hot {
        color: #F50537;
      }
    }
    .item-wrapper {
      box-sizing: border-box;
      padding: 8px 10px;
      position: relative;
    }
    .item {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      line-height: 24px;
      cursor: pointer;
      transition-duration: 0.2s;
      display: flex;
      align-items: center;
      .text {
        max-width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .item-search-icon {
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        margin-right: 10px;
      }
      .item-search-hot {
        width: 16px;
        height: 16px;
      }
      .item-search-arrow {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
      }
    }
    .item-wrapper:hover {
      background: #f5f5f5;
    }
  }
}
</style>
<style lang="less">
  .myvantabblack{
    h1{color:#ffffff;}
  }
  .myvantab{
    margin:10px 10px;
    .van-tabs__wrap{padding:0px !important;}
    .van-tabs__line{display:none !important;}
    .van-tab{
      background-color:#808080;
      color:#ffffff;
    }
    h1{font-size:14px;}
    .van-tab--active{
      background-color:#ff9400;
    }
    .myvantabcondiv{
      padding-top:10px;
      padding-bottom:10px;
    }
  }
</style>
