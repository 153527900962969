<template>
  <div id="vsearch" class="vsearch" :class="$route.name === 'vSearchHome' ? 'vsearch-bg' : ''">
    <div v-if="showPage">
      <div v-show="needReturnBtn" :class="_userAgentType().platform === 'pc' ? 'to_header_pc' : 'to_header'">
        <to-header />
      </div>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
  </div>
</template>

<script>
import { CUSTOM_USER_INFO } from '@/modules/vSearch/customUserInfo'
import { mapGetters } from 'vuex'
// import { getUser } from '@/scripts/userAuth'
import wxSDK from '@/scripts/wxsdk'
import store from '@/store/index'
import Router from '@/router'
import { getUrlParamsObj } from '@/scripts/utils'
import toHeader from './components/Header'
export default {
  components: { toHeader },
  data() {
    return {
      inited: false,
      needReturnBtn: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    showPage() {
     
      return true
    }
  },
  watch: {
    async $route(_to, _from) {
      if (!this.inited) {
        await this.init()
      }
      this.needReturnBtn =
        Router.history.current.name !== 'vSearchHome' &&
        !getUrlParamsObj().notInQw
    }
  },
  async created() {
    // await this.init()
    // console.log('ExternalContact:', await wxSDK.getCurExternalContact()) // 会触发企微的loading
  },
  mounted() {
    this.needReturnBtn =
      Router.history.current.name !== 'vSearchHome' &&
      !getUrlParamsObj().notInQw
 
  },
  methods: {
   
  }
}
</script>

<style lang="less" scoped>
.vsearch {
  overflow-y: scroll;
  height: 100vh;
}
.vsearch-bg {
  background: url('./img/homeBg@2x.png') no-repeat;
  background-size: 100% 100%;
}
.return-btn-wrapper {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 16px;
  .van-button {
    width: 100px;
    height: 32px;
    font-size: 16px;
    border-color: #000;
    border-radius: 0;
  }
}
.to_header {
  width: 100%;
}
.to_header_pc {
  height: 44px;
  width: 100%;
}
</style>
