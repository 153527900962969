<template>
  <div>
    <div v-if="$route.name === 'vSearchHome' || (_userAgentType().platform !== 'pc' && $route.name !== 'posterMake')" />
    <div v-else class="header">
      <span class="to_back" @click.stop="returnPage">
        <van-icon name="arrow-left" />
      </span>
      <p class="header_title">{{ $route.meta.routeName }}</p>
    </div>
  </div>
</template>

<script>
import Router from '@/router'

export default {
  name: 'Header',
  data() {
    return {
      proj: process.env.VUE_APP_PROJ
    }
  },
  created() {
  },
  methods: {
    returnPage() {
      console.log(123, Router.history.current.name, this.$router)
      this.$router.go(-1)
      // if (Router.history.current.name === 'searchList') {
      //   this.$router.push({ name: 'vSearchHome' })
      // }
      // if (Router.history.current.name === 'map') {
      //   this.$router.go(-1)
      // }
      // else {
      //   this.$router.go(-1)
      // }
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 20px;
  background: #fff;
  display: flex;
  padding: 12px 0px;
  z-index: 10000;
  box-shadow: 0 0 4px 5px rgba(141, 141, 141, 0.05);
  .to_back {
    color: #000;
    z-index: 99;
    margin: 0 0 0px 20px;
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
  .header_title {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    color: #000;
  }
}
</style>
