import request from '@/utils/request'
import SERVER from './server'
import { genSignHeaders } from '@/utils/signUtil'

// 获取素材id
export const getMaterialId = data =>
  request.post('/qw-third' + '/scrm-third-system-service/api/v1/cms/inner/material/mediaid', data)

/**
 * 获取素材分组
 */
export const getMaterialCategory = params =>
  request.post('/qw-third' + '/scrm-third-system-service/api/v1/cms/inner/group/list', params)

/**
 * 获取素材列表
 */
export const getMaterial = params =>
  request.post('/qw-third' + '/scrm-third-system-service/api/v1/cms/inner/material/list', params)

/**
 * 素材详情
 */
export const articleMaterialDetail = params =>
  request.post('/external' + SERVER.THIRD_SYSTEM_SERVICE + '/api/v1/cms/inner/material/detail', params,
    { headers: genSignHeaders({ host: 'scrm-third-system-gateway', method: 'POST', uri: '/external' + SERVER.THIRD_SYSTEM_SERVICE + '/api/v1/cms/inner/material/detail', requestBody: params }) })

// TODO
export const searchHistory = async ()=> ({})
export const searchSuggest = async ()=> ({})
export const recommendSearchChart = async ()=> ({})
export const recommendSearchImageAndVideo = async ()=> ({})
export const clearHistory = async ()=> ({})
export const recommendSearchArticle = async ()=> ({})
export const linkList = async ()=> ({})
export const removeHistory = async ()=> ({})