<!--  -->
<template>
  <div>
    <public-detail></public-detail>
  </div>
</template>

<script>
import publicDetail from '@/modules/vSearch/components/publicDetail'
export default {
  components: { publicDetail },
  data() {
    return {}
  },

  computed: {},

  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
</style>
