<template>
  <div class="v-search-home">
    <div class="top" @click="proj !== 'audi' ? closeSearch : ''">
      <img
        v-if="!isInIframe"
        src="@/modules/vSearch/img/user@2x.png"
        alt=""
        @click="$router.push({ name: 'userCenterHome' })"
      />
    </div>
    <div class="middle">
      <div class="title">知识库</div>
      <TopSearch
        :tabs-data="tabsData"
        :top-search-map="topSearchMap"
        :show-associative-data="showAssociativeData"
        :background="'black'"

        :search-params="searchParams"
        :top-recommend-search-map-week="topRecommendSearchMapWeek"
        :top-recommend-search-map-total="topRecommendSearchMapTotal"
        :has-fixed-btn="false"

        :list="list"
        maxlength="50"
        @search="search"
        @typeChange="typeChange"
      />
    </div>
    <div v-show="topSearchMap.type !== '-1'" class="bottom">
      <div class="title" @click="closeSearch">
        <div>历史搜索</div>
        <img v-if="!delHistory && searchHistoryData.length" class="icon-del" src="@/modules/vSearch/img/icon_history_del.png" alt="" @click="delHistory = true">
        <div v-if="delHistory">
          <span class="del-all" @click="delHistoryVisible = true">全部删除</span>
          <span class="del-submit" @click="delHistory = false">完成</span>
        </div>
      </div>
      <div class="items-wrapper" @click="closeSearch">
        <div
          v-for="(item, index) in searchHistoryData"
          v-show="item.keyword !== '' && (index > 4 ? isHistoryOpen : true)"
          :key="index"
          class="item"
          :title="item.keyword"
        >
          <div
            class="item-text"
            @click="
              search({
                keyWord: item.keyword,
                type: topSearchMap.type,
                querySource: 4
              })
            "
          >
            {{ item.keyword }}
          </div>
          <img
            v-if="delHistory"
            class="icon-remove"
            src="@/modules/vSearch/img/icon_history_rem.png"
            alt=""
            @click="removeHistory(item.keyword)"
          >
        </div>
        <span
          v-show="searchHistoryData.length > 5"
          class="item item-btn"
          @click="isHistoryOpen = !isHistoryOpen"
        >
          {{ isHistoryOpen ? '收起' : '展开' }}
        </span>
      </div>
    </div>
    <van-dialog
      v-model="delHistoryVisible"
      :show-confirm-button="false"
    >
      <div class="del-history">
        <div class="del-title">清空搜索历史</div>
        <div class="del-content">确认清空全部搜索历史？</div>
        <div class="del-btns">
          <div class="btn-cancel" @click="delHistoryVisible = false">取消</div>
          <div class="btn-confirm" @click="delHistoryAll">清除</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import TopSearch from '../components/TopSearch.vue'
import { searchHistory, clearHistory, removeHistory, recommendSearchImageAndVideo, recommendSearchArticle, recommendSearchChart } from '@/services/materialServices'
import { TABS_DATA } from '@/modules/vSearch/constConfig'
export default {
  name: 'VSearchHome',
  components: { TopSearch },
  data() {
    return {
      proj: process.env.VUE_APP_PROJ,
      delHistoryVisible: false, // 删除搜索历史弹窗
      delHistory: false, // 删除按钮
      showAssociativeData: false,
      topSearchMap: {
        keyWord: '',
        type: '0'
      },
      tabsData: TABS_DATA[process.env.VUE_APP_PROJ],
      searchHistoryData: [],
      isHistoryOpen: false,
      list: [],
      // 推荐 -> 周排行  奥迪
      topRecommendSearchMapWeek: {
        'image': {
          keyWord: '',
          type: '3',
          list: []
        },
        'video': {
          keyWord: '',
          type: '5',
          list: []
        },
        'article': {
          keyWord: '',
          type: '2',
          list: []
        },
        'char': {
          keyWord: '',
          type: '1',
          list: []
        }
      },
      // 推荐 -> 总排行  奥迪
      topRecommendSearchMapTotal: {
        'image': {
          keyWord: '',
          type: '3',
          list: []
        },
        'video': {
          keyWord: '',
          type: '5',
          list: []
        },
        'article': {
          keyWord: '',
          type: '2',
          list: []
        },
        'char': {
          keyWord: '',
          type: '1',
          list: []
        }
      },
      searchParams: {
        keyword: '',
        materialType: 1,
        querySource: '',
        publishTime: '',
        beginTime: '',
        endTime: '',
        categoryLv1Ids: [],
        categoryLv2Ids: [],
        pageIndex: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    isInIframe() {
      return self !== top
    }
  },
  created() {
    if (this.proj === 'audi') {
      this.topSearchMap.type = '-1'
      if (this.topSearchMap.type === '-1') {
        this.loadImageAndVideo(1)
        this.loadImageAndVideo(2)
        this.loadArticle(1)
        this.loadArticle(2)
        this.loadChart(1)
        this.loadChart(2)
        this.$forceUpdate()
      }
    }
  },
  mounted() {
    this.searchHistory()
  },
  methods: {
    async removeHistory(keyword) {
      await removeHistory({ keyword })
      this.searchHistory()
    },
    async delHistoryAll() {
      const res = await clearHistory()
      if (res.code === 0) {
        this.searchHistoryData = []
        this.delHistoryVisible = false
        this.delHistory = false
      }
    },
    closeSearch() {
      this.showAssociativeData = true
      this.showAssociativeData = false
    },
    async searchHistory() {
      const res = await searchHistory()
      if (res.code === 0) {
        // res.data.history = res.data.history.slice(0, 11)
        this.searchHistoryData = [...res.data.history]
      }
    },
    search(topSearchMap) {
      topSearchMap.keyWord = this._trim(topSearchMap.keyWord)
      this.$router.push({
        name: 'searchList',
        params: { ...topSearchMap }
      })
    },
    typeChange(topSearchMap) {
      this.topSearchMap = { ...topSearchMap }
      // 奥迪专用
      if (this.topSearchMap.type === '-1') {
        this.loadImageAndVideo(1)
        this.loadImageAndVideo(2)
        this.loadArticle(1)
        this.loadArticle(2)
        this.loadChart(1)
        this.loadChart(2)
        this.$forceUpdate()
      }
    },

    // 加载图片/视频 周排行
    async loadImageAndVideo(range) {
      const res = await recommendSearchImageAndVideo({
        'range': range
      }).catch(() => {})
      // 图片类
      const imgList = res && res.data && res.data.imgList || []
      const mediaList = res && res.data && res.data.mediaList || []
      mediaList.map((item, index) => {
        if (item.tags && item.tags.length > 0) {
          let tags = ''
          item.tags.forEach((xt) => {
            tags += xt.tagName + ','
          })
          tags = tags.substring(0, tags.lastIndexOf(','))
          item.tags = tags
        }
        else {
          item.tags = ''
        }
        item.cardData = {
          ...item,
          id: item.id,
          title: item.title,
          desc: item.description,
          content: '',
          hasImageUrl: item.videoCoverUrl,
          imageUrl: item.videoCoverUrl + '?imageMogr2/quality/40',
          orgName: item.orgName,
          time: item.updateTime,
          format: item.extension,
          materialType: '5',
          materialId: item.id,
          topStr: 'TOP' + (index + 1),
          duration: Number(parseInt(item.duration)) // 单位秒
        }
      })
      imgList.map((item, index) => {
        if (item.tags && item.tags.length > 0) {
          let tags = ''
          item.tags.forEach((xt) => {
            tags += xt.tagName + ','
          })
          tags = tags.substring(0, tags.lastIndexOf(','))
          item.tags = tags
        }
        else {
          item.tags = ''
        }
        item.cardData = {
          ...item,
          id: item.id,
          title: item.title,
          desc: item.description,
          content: '',
          imageUrl: item.cosPath + '?imageMogr2/quality/40',
          orgName: item.orgName,
          time: item.updateTime,
          topStr: 'TOP' + (index + 1),
          materialType: '3',
          materialId: item.id
        }
      })
      if (range === 1) {
        this.topRecommendSearchMapWeek.image.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapWeek.image.list = imgList
        this.topRecommendSearchMapWeek.video.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapWeek.video.list = mediaList
      }
      else {
        this.topRecommendSearchMapTotal.image.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapTotal.image.list = imgList
        this.topRecommendSearchMapTotal.video.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapTotal.video.list = mediaList
      }
    },
    // 加载文章周排行
    async loadArticle(range) {
      const res = await recommendSearchArticle({
        'range': range
      }).catch(() => {})
      const articleList = res && res.data && res.data.contentList || []
      articleList.map((item, index) => {
        if (item.tags && item.tags.length > 0) {
          let tags = ''
          item.tags.forEach((xt) => {
            tags += xt.tagName + ','
          })
          tags = tags.substring(0, tags.lastIndexOf(','))
          item.tags = tags
        }
        else {
          item.tags = ''
        }
        item.cardData = {
          ...item,
          id: item.contentId,
          title: item.title,
          desc: item.description,
          content: '',
          imageUrl: item.imgUrl + '?imageMogr2/quality/40',
          orgName: item.orgName,
          time: item.updateTime,
          topStr: 'TOP' + (index + 1),
          materialType: '2',
          materialId: item.contentId
        }
      })
      if (range === 1) {
        this.topRecommendSearchMapWeek.article.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapWeek.article.list = articleList
      }
      else {
        this.topRecommendSearchMapTotal.article.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapTotal.article.list = articleList
      }
    },
    // 加载话术 周排行
    async loadChart(range) {
      const res = await recommendSearchChart({
        'range': range
      }).catch(() => {})
      const chartList = res && res.data && res.data.chatList || []
      chartList.map((item, index) => {
        item.tags = JSON.parse(item.tags)
        if (item.tags && item.tags.length > 0) {
          let tags = ''
          item.tags.forEach((xt) => {
            tags += xt.tagName + ','
          })
          tags = tags.substring(0, tags.lastIndexOf(','))
          item.tags = tags
        }
        else {
          item.tags = ''
        }
        item.materialId = item.id
        item.cardData = {
          ...item,
          id: item.id,
          materialId: item.id,
          title: item.title,
          desc: item.content,
          content: item.content,
          imageUrl: '',
          orgName: item.groupName,
          time: item.updateTime,
          topStr: 'TOP' + (index + 1),
          materialType: '1',
          hasLinkMaterial: item.link
        }
      })
      if (range === 1) {
        this.topRecommendSearchMapWeek.char.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapWeek.char.list = chartList
      }
      else {
        this.topRecommendSearchMapTotal.char.keyWord = this.topSearchMap.keyWord
        this.topRecommendSearchMapTotal.char.list = chartList
      }
    }
  }
}
</script>

<style lang="less" scope>
.del-history {
  position: relative;
  box-sizing: border-box;
  padding: 0 30px 24px 30px;
  .del-title {
    margin-top: 38px;
    font-family: DFPKingGothicGB-Medium;
    font-weight: 500;
    font-size: 16px;
    color: #050404;
  }
  .del-content {
    margin-top: 20px;
    font-family: DFPKingGothicGB-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #050404;
    line-height: 21px;
  }
  .del-btns {
    display: flex;
    margin-top: 70px;
    justify-content: flex-end;
    .btn-cancel {
      border: 1px solid #000000;
      font-family: DFPKingGothicGB-Regular;
      font-weight: 400;
      font-size: 16px;
      color: #050404;
      line-height: 24px;
      padding: 4px 29px;
    }
    .btn-confirm {
      margin-left: 10px;
      background: #000000;
      border: 1px solid #000000;
      font-family: DFPKingGothicGB-Regular;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 24px;
      padding: 4px 29px;
    }
  }
}
.van-dialog {
  border-radius: 0;
}
.v-search-home {
  height: 100vh;
  padding: 0 18px;
  .top {
    padding: 40px 0 40px 0;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .middle {
    .title {
      font-family: DFPKingGothicGB-Semibold;
      font-weight: 600;
      font-size: 28px;
      color: #FFFFFF;
      letter-spacing: -0.42px;
      text-align: center;
      line-height: 44px;
      margin-bottom: 48px;
    }
  }
  .bottom {
    margin-top: 42px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      font-family: DFPKingGothicGB-Regular;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 24px;
      .icon-del {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
      .del-all {
        cursor: pointer;
      }
      .del-submit {
        cursor: pointer;
        margin-left: 20px;
      }
    }
    .items-wrapper {
      min-height: 300px;
      .item {
        position: relative;
        display: inline-block;
        font-family: DFPKingGothicGB-Regular;
        font-weight: Regular;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        padding: 10px;
        margin-right: 30px;
        margin-bottom: 20px;
        border: 1px solid #FFFFFF;
        cursor: pointer;
        .item-text {
          max-width: 131px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icon-remove {
          position: absolute;
          right: -8px;
          top: -8px;
          width: 16px;
          height: 16px;
        }
      }
      .item-btn {
        cursor: pointer;
        border: none;
        margin-right: 0;
        text-decoration: underline;
        float: right;
        // color: #fff;
      }
    }
    // .van-collapse {
    //   .van-collapse-item {
    //     .van-cell--clickable {
    //       background: transparent;
    //       line-height: 24px;
    //     }
    //     .van-collapse-item__wrapper {
    //       .van-collapse-item__content {
    //         background: transparent;
    //         padding: 0;
    //         .items {
    //           display: inline-block;
    //           border: 1px solid #999999;
    //           padding: 7px 14px;
    //           margin: 16px 16px 0 0;
    //           font-family: DFPKingGothicGB-Regular;
    //           font-weight: Regular;
    //           font-size: 14px;
    //           color: #999999;
    //           max-width: 153px;
    //           white-space: nowrap;
    //           overflow: hidden;
    //           text-overflow: ellipsis;
    //         }
    //       }
    //     }
    //     .van-collapse-item__title {
    //       padding: 0;
    //       margin-bottom: 7px;
    //       font-family: DFPKingGothicGB-Medium;
    //       font-weight: Medium;
    //       font-size: 16px;
    //       color: #999999;
    //       letter-spacing: 0;
    //       .van-cell__right-icon::before {
    //         content: '展开';
    //         transform: rotate(0deg);
    //         font-family: PingFangSC-Regular;
    //         font-weight: Regular;
    //         font-size: 12px;
    //         color: #666666;
    //       }
    //       .van-cell__right-icon::after {
    //         content: '\e65e';
    //         font-size: 12px;
    //         transform: rotate(90deg) translateZ(0);
    //         margin-left: 3px;
    //         color: #666666;
    //       }
    //     }
    //     .van-collapse-item__title--expanded {
    //       .van-cell__right-icon::before {
    //         content: '收起';
    //         transform: rotate(0deg);
    //         font-family: PingFangSC-Regular;
    //         font-weight: Regular;
    //         font-size: 12px;
    //         color: #666666;
    //       }
    //       .van-cell__right-icon::after {
    //         content: '\e65f';
    //         font-size: 12px;
    //         transform: rotate(90deg) translateZ(0);
    //         color: #666666;
    //       }
    //     }
    //   }
    // }
  }
}
</style>
